























import InteractionLib from "@/utils/interaction.lib";
import OrderCard from "@/views/mine/components/OrderCard.vue";

import { Icon, CellGroup, Cell } from "vant";
import { Component, Vue } from "vue-property-decorator";
@Component({
  components: {
    Icon,
    CellGroup,
    Cell,
    OrderCard,
  },
})
export default class ListItem extends Vue {
  toDemand() {
    if (InteractionLib.isApp()) {
      return InteractionLib.openWebView(`${location.origin}/demand`);
    }
    this.$router.push({ path: "/demand" });
  }
  toMoneyDetail() {
    if (InteractionLib.isApp()) {
      return InteractionLib.openWebView(`${location.origin}/moneydetail`);
    }
    this.$router.push({ path: "/moneydetail" });
  }
  toCommonProblem() {
    if (InteractionLib.isApp()) {
      return InteractionLib.openWebView(`${location.origin}/common-problem`);
    }
    this.$router.push({ path: "/common-problem" });
  }
}
