<template>
  <div class="wrap">
    <div class="flex-column">
      <div style="font-size: 12px; color: white">我的蒸汽币</div>
      <div style="display:flex;align-items:center">
        <img src="https://cdn.vgn.cn/static/Official-website/coins.png" alt="" style="margin-top:5px;object-fit:cover;width:15px;height:15px;margin-right:5px">
      <div
        style="font-size: 24px; font-weight: 800; color: white; margin-top: 5px"
      >
        {{money}}
      </div>
      </div>
    </div>
  </div>
</template>

<script>
import { Component, Prop, Vue } from "vue-property-decorator";

@Component
export default class BottomLayout extends Vue {
  @Prop() money
  show = false;
  styleState = true;
  combine(e) {
    if (e) {
      this.styleState = e;
    } else {
      this.styleState = e;
    }
  }
  close() {
    this.show = false;
  }
  withdrawal = () => {
    this.$router.push("getmoney");
  };
  pay() {
    if (this.show) {
      this.show = false;
      this.styleState = true;
    }
    this.show = true;
  }
  
}
</script>

<style lang="scss" scoped>
.wrap {
  height: 98px;
  background: linear-gradient(90deg, #2f3c56 0%, #1b202b 100%);
  border-radius: 8px;
  display: flex;
  justify-content: space-between;
  padding: 0 20px;
  align-items: center;
  .flex-column {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }
  .withdrawal {
    position: relative;
    .org-tips {
      position: absolute;
      width: 60px;
      height: 25px;
      font-size: 12px;
      color: white;
      display: flex;
      justify-content: center;
      background-image: url("https://cdn.vgn.cn/static/org.png");
      background-repeat: no-repeat;
      background-size: 100%;
      left: 5px;
    }
    .button {
      margin-top: 25px;
      width: 69px;
      height: 28px;
      background: #ffffff;
      border-radius: 14px;
      color: black;
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: 14px;
      font-weight: 600;
    }
  }
}
</style>
