<template>
  <div class="lockmoney">
    <div class="flex-row">
      <div class="flex-column">
        <div class="flex-rows">
          <div style="color: #878e99; font-size: 12px">
            <span>锁定金额</span><span style="margin-left: 5px">(元)</span>
          </div>
          <Icon style="margin-left: 5px" name="question-o" color="#878E99" @click="toCommonQ"/>
        </div>
        <div style="font-size: 16px; font-weight: 800; margin-top: 5px">
          {{money.freeze_money}}
        </div>
      </div>
      <div class="shu"></div>

      <div class="flex-column" style="margin-left: 90px">
        <div style="color: #878e99; font-size: 12px">我的瓶盖</div>
        <div class="flex-rows" style="margin-top: 5px">
          <img
            class="bottleimg"
            src="https://cdn.vgn.cn/static/Official-website/bottle.png"
            alt=""
          />
          <div style="font-size: 16px; font-weight: 800; margin-left: 5px">
            {{userInfo.reward_point}}
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script >
import { Component, Vue, Prop } from "vue-property-decorator";
import { Icon } from "vant";
import { getModule } from "vuex-module-decorators";
import GlobalStateStore from "@/store/modules/global-state.store";

@Component({
  components: {
    Icon,
  },
})
export default class LockMoney extends Vue {
  @Prop() money
  globalState = getModule(GlobalStateStore)

  get userInfo() {
    return this.globalState.userInfo;
  }
  async created() {
    if(!this.userInfo) {
      await this.globalState.getUserInfo()
    }
  }
  toCommonQ() {
    this.$router.push({name:'commonproblem'})
  }
}
</script>

<style lang="scss" scoped>
 .shu {
    position: absolute;
    top: 35px;
    height: 30px;
    width: 1px;
    border-left: 1px solid #dddddd;
    left: 50%;
  }
.lockmoney {
  position: relative;
  box-sizing: border-box;
  height: 89px;
  background: #ffffff;
  border-radius: 8px;
  .flex-row {
    padding: 20px 10px;
    box-sizing: border-box;
    height: 89px;
    display: flex;
    align-items: center;
    .flex-column {
      display: flex;
      justify-content: space-between;
      flex-direction: column;
      .bottleimg {
        width: 15px;
        height: 15px;
      }
      .flex-rows{
         display: flex;
        align-items: center;
      }
    }
  }
}
</style>
