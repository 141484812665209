<template>
  <div style="padding: 0 10px">
    <CheckMoney style="margin-top: 10px" :money="money.balance_money" />
    <LockMoney style="margin-top: 10px" :money="money" />
    <ListItem style="margin-top: 10px" />
    <img
    v-if="wechat"
      src="~@/assets/backtohome.png"
      class="more_welfare_btn"
      @click="tohome"
    />
  </div>
</template>

<script>
import { Component, Vue } from "vue-property-decorator";
import CheckMoney from "@/views/wallet/components/CheckMoney.vue";
import LockMoney from "@/views/wallet/components/LockMoney.vue";
import ListItem from "@/views/wallet/components/ListItem.vue";
import BazzarApi from "@/api/bazaar.api";
import InteractionLib from "@/utils/interaction.lib";
@Component({
  components: {
    CheckMoney,
    LockMoney,
    ListItem,
  },
})
export default class Wallet extends Vue {
  money = [];
  wechat = false
  async created() {
    this.money = await BazzarApi.getMoney();
    this.wechat = InteractionLib.mobile.wechat;
  }
  tohome() {
    this.$router.push({ path: "/" });
  }
}
</script>

<style lang="scss" scoped>
.more_welfare_btn {
  position: fixed;
  width: 90px;
  height: 63px;
  background-repeat: no-repeat;
  background-size: 100% 100%;
  bottom: 60px;
  left: -2px;
  z-index: 20;
}
</style>